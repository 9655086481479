import { Box, Button, Divider, Heading, HStack, Icon, Image, Link, Menu, MenuButton, MenuItem, MenuList, SimpleGrid, useMediaQuery, VStack } from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
import { BsApple } from 'react-icons/bs';
import { SiMicrosoftoutlook } from 'react-icons/si';
import React from 'react';
import CustomModal from './Modal';
import receptionPhoto from '../../assets/recepcion.jpg'
import temploPhoto from '../../assets/templo.jpg'

export default function Rendezvous() {
    const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)');
    
    return (
        <VStack>
            <Box w='100%' display='grid' placeItems='center' mt='10' mb='10'>
                <Heading as='h1' size={isLargerThan1280 ? '3xl' : '2xl' } color='purple.400' style={{ fontFamily: 'Satisfy' }} >Dónde y Cuándo</Heading>
            </Box>
            <SimpleGrid w="75%" minChildWidth="200px" spacing='20px' placeItems="center" style={{ zIndex: '1'}}  p='3'>
                <Box maxW={isLargerThan1280 ? 'sm' : 'xs'} borderWidth='1px' borderRadius='lg' overflow='hidden' >
                    <Image src={temploPhoto} alt='Templo de Cristo Sacerdote' style={{width: "100%", height:"258px", objectFit: "cover"}}/>

                    <Box p='3' bgColor='white'>
                        <Box display='flex' alignItems='baseline'>
                                <Heading size='md' fontWeight='black'>CEREMONIA RELIGIOSA</Heading>
                        </Box>
                        <Divider />
                        <Box
                            mt='2'
                            fontWeight='bold'
                            as='h3'
                            lineHeight='tight'
                        >
                            Basílica Lateranense
                        </Box>
                        <Box color='gray.600' mt='0.5'>
                            <Box as='span' fontWeight='semibold' fontSize='md'>
                                Cuándo:
                            </Box>
                            24 de febrero, 2024; 18:00 Hrs.
                        </Box>

                        <Box color='gray.600'>
                            <Box as='span' fontWeight='semibold' fontSize='md'>
                                Dirección:
                            </Box>
                            Pedro Loza 18, Juanacatlán Centro, 45880 Juanacatlán, Jal.
                        </Box>
                        <HStack mt='6' justifyContent='center'>
                            <CustomModal
                                showModalButtonText='Ver mapa'
                                modalHeader='CEREMONIA RELIGIOSA'
                                modalSrc='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7473.980371116389!2d-103.17118775605682!3d20.506628581526588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842f35f7a9c2c83b%3A0x298bfa895be73c69!2sBas%C3%ADlica%20Lateranense!5e0!3m2!1ses-419!2smx!4v1705469693922!5m2!1ses-419!2smx'
                                modalTitle='Basílica Lateranense'
                                modalDate='24 de febrero, 2024; 18:00 Hrs.'
                                modalAddress='Pedro Loza 18, Juanacatlán Centro, 45880 Juanacatlán, Jal.' />
                            <Menu>
                                <MenuButton as={Button} bgColor='purple.300' color='white' >
                                    Agregar a calendario
                                </MenuButton>
                                <MenuList>
                                    <MenuItem minH='48px' as={Link} style={{ textDecoration: 'none' }} href='https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=NTc4a2cyb2plYTNqbXJob2JhMjI2amUycXIgMTRmMGJhNDdhY2QzZDdjZDUyZDZhOTg4ZDFiMmMzOWU3MzcwM2NhZTcwZmEwMzZiMWJmNjMwNmFmZmNlZTg5YkBn&tmsrc=14f0ba47acd3d7cd52d6a988d1b2c39e73703cae70fa036b1bf6306affcee89b%40group.calendar.google.com' isExternal>
                                        <Icon as={FcGoogle} boxSize='2rem' mr='12px'/>
                                        Google Calendar
                                    </MenuItem>
                                    <MenuItem minH='48px' as={Link} style={{ textDecoration: 'none' }} href='/XV Años de Iyari - Ceremonia Religiosa.ics'>
                                        <Icon as={BsApple} boxSize='2rem' mr='12px' />
                                        Apple Calendar
                                    </MenuItem>
                                    <MenuItem minH='48px' as={Link} style={{ textDecoration: 'none' }} href='/XV Años de Iyari - Ceremonia Religiosa.ics'>
                                        <Icon as={SiMicrosoftoutlook} boxSize='2rem' mr='12px' />
                                        Outlook
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                    </Box>
                </Box>
                <Box maxW={isLargerThan1280 ? 'sm' : 'xs'} borderWidth='1px' borderRadius='lg' overflow='hidden'>
                    <Image src={receptionPhoto} alt='Las Calandrias Eventos' style={{width: "100%", height:"258px", objectFit: "cover"}}/>

                    <Box p='3' bgColor='white'>
                        <Box display='flex' alignItems='baseline'>
                                <Heading size='md' fontWeight='black'>RECEPCIÓN</Heading>
                        </Box>
                        <Divider />
                        <Box
                            mt='2'
                            fontWeight='bold'
                            as='h3'
                            lineHeight='tight'
                            isTruncated
                        >
                            Aura Lounge
                        </Box>
                        <Box color='gray.600' mt='0.5'>
                            <Box as='span' fontWeight='semibold' fontSize='md'>
                                Cuándo:
                            </Box>
                            24 de febrero, 2024; 20:00 Hrs.
                        </Box>

                        <Box color='gray.600'>
                            <Box as='span' fontWeight='semibold' fontSize='md'>
                                Dirección:
                            </Box>
                            Constitución 501, Potrero Nuevo, 45680 El Salto, Jal.
                        </Box>
                        <HStack mt='6' justifyContent='center'>
                            <CustomModal
                                showModalButtonText='Ver mapa'
                                modalHeader='RECEPCIÓN'
                                modalSrc='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5284.557234856897!2d-103.19023769298519!3d20.51662564390552!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842f4a6b89c67097%3A0x929c47cc559f030!2sAura%20Lounge!5e0!3m2!1ses-419!2smx!4v1705470021313!5m2!1ses-419!2smx'
                                modalTitle='Aura Lounge'
                                modalDate='24 de febrero, 2024; 20:00 Hrs.'
                                modalAddress='Constitución 501, Potrero Nuevo, 45680 El Salto, Jal.' />
                            <Menu>
                                <MenuButton as={Button} bgColor='purple.300' color='white'>
                                    Agregar a calendario
                                </MenuButton>
                                <MenuList>
                                    <MenuItem minH='48px' as={Link} style={{ textDecoration: 'none' }} href='https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=MzUzODZhdWN1bGo4OXNrNmM0NmdmN3JmNWogMTRmMGJhNDdhY2QzZDdjZDUyZDZhOTg4ZDFiMmMzOWU3MzcwM2NhZTcwZmEwMzZiMWJmNjMwNmFmZmNlZTg5YkBn&tmsrc=14f0ba47acd3d7cd52d6a988d1b2c39e73703cae70fa036b1bf6306affcee89b%40group.calendar.google.com' isExternal>
                                        <Icon as={FcGoogle} boxSize='2rem' mr='12px'/>
                                        Google Calendar
                                    </MenuItem>
                                    <MenuItem minH='48px' as={Link} style={{ textDecoration: 'none' }} href='/XV Años de Iyari - Recepcion.ics'>
                                        <Icon as={BsApple} boxSize='2rem' mr='12px' />
                                        Apple Calendar
                                    </MenuItem>
                                    <MenuItem minH='48px' as={Link} style={{ textDecoration: 'none' }} href='/XV Años de Iyari - Recepcion.ics'>
                                        <Icon as={SiMicrosoftoutlook} boxSize='2rem' mr='12px' />
                                        Outlook
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                    </Box>
                </Box>
            </SimpleGrid>
            {/* <Box w='100%' display='grid' placeItems='center' pt='6' pb='6' bgColor='gray.400'>
               <Text color='white' textAlign='center' pl='2' pr='2'>"El amor no consiste en mirarse el uno al otro, si no, ver juntos en la misma dirección."</Text>
               <Text color='white' textAlign='center' pl='2'>-Antoine de Saint-Exupéry</Text>
            </Box> */}
        </VStack>
    )
};