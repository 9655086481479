import { Box, Grid, GridItem, Heading, useMediaQuery, VStack } from '@chakra-ui/react';
import React from 'react';

function Papas() {
    const [desktopScreen] = useMediaQuery('(min-width: 1280px)');
    return (
    <VStack>
        <Box w='100%' display='grid' placeItems='center' mb='1' justifyItems='center' p='4'>
            <Heading as='h1' size='xl' color='purple.300' mt='6' style={{ fontFamily: 'Satisfy' }} textAlign='center'>Nosotros</Heading>
        </Box>
        <Grid
                minHeight='150px'
                w='80%'
                templateRows= 'repeat(3, 1fr)'
                templateColumns= 'repeat(3, 1fr)'
                gap={1}
            >
                <GridItem colSpan={desktopScreen ? 3 : 3}>
                    <VStack>
                        <Heading as='h1' size='lg' color='gray.600' style={{ fontFamily: 'Montserrat' }} textAlign='center'>Seidy Yanet Villalpando Del Ángel</Heading>
                        <Heading as='h1' size='lg' color='gray.600' style={{ fontFamily: 'Montserrat' }} textAlign='center'>&amp;</Heading>
                        <Heading as='h1' size='lg' color='gray.600' style={{ fontFamily: 'Montserrat' }} textAlign='center'>César Octavio Suárez Orozco</Heading>
                    </VStack>
                </GridItem>
                <GridItem colSpan={desktopScreen ? 3 : 3}>
                    <Box w='100%' h='50%' display='grid' placeItems='center' mt='10' justifyItems='center'>
                        <Heading as='h1' size='lg' color='purple.300' style={{ fontFamily: 'Satisfy' }} textAlign='center'>Y la compañia de sus padrinos</Heading>
                    </Box>
                </GridItem>
                <GridItem colSpan={desktopScreen ? 3 : 3}>
                    <VStack>
                        <Heading as='h1' size='lg' color='gray.600' style={{ fontFamily: 'Montserrat' }} textAlign='center'>Mayra Guadalupe Valdés Puente</Heading>
                        <Heading as='h1' size='lg' color='gray.600' style={{ fontFamily: 'Montserrat' }} textAlign='center'>&amp;</Heading>
                        <Heading as='h1' size='lg' color='gray.600' style={{ fontFamily: 'Montserrat' }} textAlign='center'>Sergio Pacheco Sotelo</Heading>
                    </VStack>
                </GridItem>
        </Grid>
        <Box w='100%' display='grid' placeItems='center' mb='5' p='5' justifyItems='center'>
            <Heading as='h1' size='xl' color='purple.500' style={{ fontFamily: 'Satisfy' }} textAlign='center'>¡Tenemos el honor de invitarlos a los XV Años de Iyari!</Heading>
        </Box>
    </VStack>
  )
}

export default Papas;
