import './App.css';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react";
import { BrowserRouter as Router, Routes, Route, Outlet} from 'react-router-dom'; 
import ImageSlide from './components/carousel/carousel';
import { Box, VStack, extendTheme, ChakraProvider, useMediaQuery } from '@chakra-ui/react';
import Rendezvous from './components/rendezvous/Rendevous';
import GiftTable from './components/giftTable/GiftTable';
import Personas from './components/personas/personas';
// import Hoteles from './components/hoteles/hoteles';
import Dresscode from './components/dresscode';
import ConfirmarWhatsapp from './components/confirmacion/whatsapp';
// import Padrinos from './components/personas/Padrinos';
import Invitados from './components/Invitados';
import Instagram from './components/instagram';
import FotoInter from './components/fotoInter';
import FotoFinal from './components/fotoFinal';
import AudioComponent from './components/audioComponents/AudioComponent';
import Footer from './components/footer';


export function IsDesktopScreen(){
  const [mobileScreen] = useMediaQuery('(min-width: 1280px)');
  return mobileScreen;
};

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route path=':id/:quantity' exact={true} element={<Invitados />} />
        </Route>
      </Routes>
    </Router>
  )
}


function Layout() {


  const config = {
    initialColorMode: 'light',
    useSystemColorMode: false,
  }

  const theme = extendTheme({config})
  
  return (
    <ChakraProvider theme={theme}>
      <div className="App">
        <VStack>
          <Box padding='0' bg='purple.50' w='100vw' h='100%'>
            <AudioComponent />
            <ImageSlide />
            <Personas />
            <Outlet />
            <Rendezvous />
            {/* <Hoteles /> */}
            {/* <Padrinos /> */}
            <FotoInter />
            <ConfirmarWhatsapp />
            <Dresscode />
            <GiftTable />
            <Instagram />
            <FotoFinal />
            <Footer />
          </Box>
        </VStack>
        <Analytics />
        <SpeedInsights />
      </div>
    </ChakraProvider>
    
  );
}
