import React from 'react'
import photo7 from '../assets/photo7.jpeg'
import { Box } from '@chakra-ui/react'

function FotoInter() {
  return (
    <>
      <Box w='100%' h='600px' overflow='hidden' m='0' filter='auto' brightness='85%' bgImage={photo7} bgSize='cover' bgPosition="center" bgRepeat='no-repeat'>
        
        </Box>
    </>
  )
}

export default FotoInter