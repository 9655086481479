import { Box, HStack, Image, Text, VStack, Link } from '@chakra-ui/react';
import Logo1 from '../assets/inviteu-mail.png';
import Logo2 from '../assets/Inviteu-logo.png';
import React from 'react';

function Footer() {
  return (
    <VStack>
        <Box w='100%' display='grid' placeItems='center' pt='5' pb='6' bgColor='red.50'>
            <HStack maxW='60%' placeContent='center' as={Link} href='https://inviteu.app/'>
                <Image src={Logo1} boxSize='15%' objectFit='contain' />
                <Image src={Logo2} boxSize='40%' objectFit='contain' />
            </HStack>
            <Text p='2' color='black'textAlign='center' fontSize='xs' style={{ fontFamily:'Montserrat'}} >¿Quieres una invitación para tu evento? <Link color='red.500' href='https://inviteu.app'>Ingresa a InviteU.App</Link> </Text>
        </Box>
    </VStack>
  )
}

export default Footer;