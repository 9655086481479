import { Heading, VStack, Box } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import BGImage from "../assets/paris.jpg"

const Invitados = () => {
    const {id} = useParams();
    const {quantity} = useParams();

    

    return (
        <VStack bgImage={BGImage} bgSize='cover' bgPosition="center" bgRepeat='no-repeat'>
            <Heading size='xl' color='purple.500' style={{ fontFamily: 'Satisfy' }} textAlign='right' pr='0' mb='4' mt='12'>Datos de los invitados</Heading>
            <Heading size='2xl' color='gray.800' style={{ fontFamily: 'Montserrat' }} textAlign='center' mb='4'>{id}</Heading>
            <Box display='grid' placeItems='center' p='3'>
                <Heading size='xl' color='gray.800' style={{ fontFamily: 'Montserrat' }} mr='2'>Número de boletos</Heading>
                <Box borderWidth='4px' borderRadius='lg' mt='3' p='4' borderColor='purple.500' bgColor='white' ><Heading size='3xl' color='purple.500' style={{ fontFamily: 'Montserrat'}} textAlign='center'>{quantity}</Heading></Box>
            </Box>
        </VStack>
    )};

export default Invitados;