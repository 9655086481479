import React from 'react';
import { Heading, Image, VStack } from '@chakra-ui/react';
import dresscodeImg from '../assets/dresscode.png';

function Dresscode() {
  return (
    <VStack pb='8'>
        <Heading size='xl' color='purple.400' style={{ fontFamily: 'Satisfy' }} mb='4' mt='12'>Código de vestimenta</Heading>
        <Image src={dresscodeImg} w='170px'/>
        <Heading size='lg' color='gray.700' style={{ fontFamily: 'montserrat' }} pb='6' mt='6'>Elegante</Heading>
    </VStack>
  )
}

export default Dresscode