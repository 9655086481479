import React from 'react';
import { Heading, Icon, Text } from '@chakra-ui/react';
import { IoIosArrowDown } from 'react-icons/io';
import './carousel.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { getRemainingTimeUntilMsTimestamp } from './Utils/contdownTimerUtils';

const defaultRemainingTime = {
    seconds: '00',
    minutes: '00',
    hours: '00',
    days: '00'
}

function Tick({countdownTimestampMs}) {

    const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

    useEffect(() => {
        const intervalId = setInterval(() => {
            updateRemainingTime(countdownTimestampMs);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [countdownTimestampMs]);

    function updateRemainingTime(countdown){
        setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
    };

    return (
        <div className="countdown">
            <div className="container">
                <Heading as='h1' size="2xl" className='names' color='purple.50' style={{ fontFamily: 'Dancing Script'}} textAlign='center' textShadow='2px 1px 4px #00000096'>XV Años</Heading>
                <Heading as='h1' size="3xl" color='purple.50' style={{ fontFamily: 'Dancing Script' }} textAlign='center' textShadow='2px 1px 4px #00000096'>Iyari Yanet</Heading>
                <Text style={{ fontFamily: 'Dancing Script' }} pt='5' color='purple.50' fontSize='4xl' textAlign='center' textShadow='2px 1px 4px #00000096'>{remainingTime.days} : {remainingTime.hours} : {remainingTime.minutes} : {remainingTime.seconds}</Text>
                <Text style={{ fontFamily: 'Montserrat' }} color='purple.50' fontSize='sm' fontWeight='bold' textAlign='center' textShadow='2px 1px 4px #00000096' mb='8'>Días · Horas · Minutos · Segundos</Text>
                <Icon as={IoIosArrowDown} color='white' w={20} h={12} />
            </div>
        </div>
    )
}

export default Tick;
