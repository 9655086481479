import React from 'react';
import { AspectRatio, Box, Button, Divider, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useMediaQuery } from '@chakra-ui/react'

function CustomModal({ showModalButtonText, modalHeader, modalSrc, modalTitle, modalDate, modalAddress }) {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [fullHD] = useMediaQuery('(min-width: 1280px)');
    
    return (
        <>
            <Button bgColor='purple.300' color='white' onClick={onOpen}>{showModalButtonText}</Button>
            <Modal isOpen={isOpen} onClose={onClose} size={fullHD ? 'lg' : 'xs'} motionPreset='slideInBottom' isCentered>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{modalHeader}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody h='900px'>
                            <Box minH='250px'>
                            <AspectRatio ratio={4 / 3}>
                                <iframe title='Mapa' src={modalSrc} style={{width: '100%', border: '0'}} loading="lazy" alt='Mapa'/>
                            </AspectRatio>
                            </Box>
                            <Box mt='2' fontWeight='bold' as='h1'>
                                {modalTitle}
                            </Box>
                            <Divider />
                            <Box color='gray.600' mt='0.5'>
                                <Box as='span' fontWeight='semibold' fontSize='md'>
                                    Cuándo:
                                </Box>
                                {' '}{modalDate}
                            </Box>

                            <Box color='gray.600'>
                                <Box as='span' fontWeight='semibold' fontSize='md'>
                                    Dirección:
                                </Box>
                                {' '}{modalAddress}
                            </Box>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme='blue' mr={3} onClick={onClose}>Cerrar</Button>
                        </ModalFooter>
                    </ModalContent>
            </Modal>
        </>
    )
}

export default CustomModal;
