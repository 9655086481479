import { VStack, Box, Heading, useMediaQuery, Image, HStack, Text, Icon } from '@chakra-ui/react';
import React from 'react';
import title01 from '../../assets/title-01.svg';
import title02 from '../../assets/title-02.svg';
import { BsEnvelopeOpenHeart } from 'react-icons/bs';

function GiftTable() {

    const [IsLargerScreen] = useMediaQuery('(min-width: 1280px)');
    return (
        <VStack pb='6' bgColor='purple.300'>
           <Box w='100%' display='grid' placeItems='center' mt='10' mb='6'>
               <HStack>
                    <Image src={title01} w={IsLargerScreen ? '200px' : '80px'} />
                    <Heading as='h1' size={IsLargerScreen ? '3xl' : 'xl'} color='gray.50' style={{ fontFamily: 'Satisfy' }} >Lluvia de Sobres</Heading>
                    <Image src={title02}w={IsLargerScreen ? '200px' : '80px'} />
               </HStack>
               <Text p='3' color='white' textAlign='center'>Preferimos que los presentes que deseen hacer, sean en efectivo colocándolo en un sobre que podrán depositar el día de la celebración.</Text>
               <Icon as={BsEnvelopeOpenHeart} boxSize={10} color='white' />
            </Box>
            {/* <Grid
                h='200px'
                w={IsLargerScreen ? '50%' : '80%'}
                templateRows= {IsLargerScreen ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'}
                templateColumns='repeat(4, 1fr)'
                gap={4}
            >
                <GridItem colSpan={4}>
                    <Button borderRadius='xl' w='100%' h='100%' bgColor='gray.50' as={Link} href='https://mesaderegalos.liverpool.com.mx/milistaderegalos/51192052' isExternal><Heading size='md' >Liverpool</Heading></Button>
                </GridItem>
                {/* <GridItem colSpan={IsLargerScreen ? 2 : 4}>
                    <Button borderRadius='xl' w='100%' h='100%' bgColor='gray.50' as={Link} href='https://www.amazon.com.mx/wedding/share/malfavon-ugalde' isExternal><Heading size='md' >Amazon</Heading></Button>
                </GridItem>
                <GridItem colSpan={4}>
                    <Popover>
                        <PopoverTrigger>
                            <Button borderRadius='xl' w='100%' h='100%' bgColor='gray.50'>
                                <Heading size={IsLargerScreen ? 'md' : 'xs'} >¿Nos ayudarías para nuestra luna de miel?</Heading></Button>
                        </PopoverTrigger>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader>¡Muchas gracias!</PopoverHeader>
                            <PopoverBody>
                                <Text> Banco BBVA</Text>
                                <Text> CLABE: 012320015624623034</Text>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                </GridItem>
            </Grid> */}
      </VStack>
  )
}

export default GiftTable;
