import React from 'react';
import { VStack, Box, Heading, HStack, useMediaQuery, Button, Link, Icon } from '@chakra-ui/react';
import { BsWhatsapp } from 'react-icons/bs';

function ConfirmarWhatsapp() {

  const [IsLargerScreen] = useMediaQuery('(min-width: 1280px)');

  return (
    <VStack bg='purple.300' pb='6'>
        <Box w='100%' display='grid' placeItems='center' mt='10' mb='6'>
           <HStack pb='5'>

                <Heading as='h1' size={IsLargerScreen ? '3xl' : 'xl'} color='gray.50' style={{ fontFamily: 'Satisfy' }}>Confirma tu asistencia</Heading>

           </HStack>
           {/* <Text p='3' color='white' textAlign='center' style={{ fontFamily: 'Montserrat'}}>Es muy importante para nosotros contar con tu confirmación antes del 16 de febrero 2024</Text> */}
           <Button borderRadius='xl' w={IsLargerScreen ? '60%' : '80%'} h='4rem' colorScheme='whatsapp' as={Link} href='https://api.whatsapp.com/send/?phone=523316235977&text=%C2%A1No%20puedo%20esperar!%20Confirmo%20mi%20asistencia.' isExternal><Icon as={BsWhatsapp} boxSize={8} color='white' mr={2} /><Heading size='md' color='white' >Confirmar asistencia</Heading></Button>

        </Box>
    </VStack>
  )
}

export default ConfirmarWhatsapp