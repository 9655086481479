import { Box, Text, VStack } from '@chakra-ui/react';
import React from 'react';

function Nombres() {
  return (
    <VStack>
        <Box w='100%' display='grid' placeItems='center' pt='3' pb='4' bgColor='purple.300'>
            <Text p='5' as='cite' color='white'textAlign='center' fontSize='lg' >Hay momentos en la vida que representan un antes y un después. El comienzo y el fin de una etapa, y lo que los hace realmente especial, son las personas con las que se comparten. Quiero que tu seas una de ellas</Text>
        </Box>
    </VStack>
  )
}

export default Nombres;
