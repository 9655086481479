import React from 'react'
import photo8 from '../assets/photo8.jpeg'
import { Box, Heading, VStack } from '@chakra-ui/react'

function FotoFinal() {
  return (
    <>
      <Box w='100%' h='600px' overflow='hidden' m='0' filter='auto' bgImage={photo8} bgSize='cover' bgPosition="center" bgRepeat='no-repeat'>
        <VStack w='100%' pt='220px' >
        <Heading as='h1' size="3xl" className='names' style={{ fontFamily: 'Satisfy' }} textAlign='center' textShadow='2px 1px 4px #00000096' >XV Años de Iyari</Heading>
        <Heading as='h1' size="2xl" style={{ fontFamily: 'Montserrat', color: 'white' }} textAlign='center' textShadow='2px 1px 4px #00000096'>¡No faltes!</Heading>
        </VStack>
      </Box>
    </>
  )
}

export default FotoFinal